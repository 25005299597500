<template>
    <div class="bkColor">
        <headTitle :title="title" :routerPath="routerPath"></headTitle>
        <van-search v-model="value" input-align="center" placeholder="请输入搜索关键词" shape="round" show-action @search="onSearch"
            @cancel="onCancel" style="margin-bottom: 2vh" />
        <div class="search">
            <div>{{ queryFrom.currentYear ? '当前年份' : '项目年份' }} :</div>
            <div style="
          display: flex;
          justify-content: space-around;
          align-items: center;
        ">
                <van-dropdown-menu>
                    <van-dropdown-item v-model="year" :options="yearOption" @change="changeYear" />
                </van-dropdown-menu>
                <div class="searchTitle">归属:</div>
                <van-dropdown-menu>
                    <van-dropdown-item v-model="queryFrom.belong" :options="belongOption" @change="changeBelong" />
                </van-dropdown-menu>
            </div>
        </div>
        <div class="list">
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                <van-list v-model="loading" :finished="finished" :error.sync="error" error-text="请求失败，点击重新加载"
                    finished-text="没有更多了" @load="getGatheringList">
                    <div class="listCell" v-for="(item, index) in list" :key="item.id" @click="goDetails(item.id)">
                        <div class="cellName">{{ item.projectName }} <span class="status"
                                :class="{ 'blue': item.state == 0, 'green': item.state == 1, 'red': item.state == 2 }"> {{
                                    stateText(item.state)
                                }}</span>
                        </div>
                        <div class="cellText" style="display: inline-block;">收款说明：<div
                                style="width:40%;display: inline-block;  vertical-align: top;">
                                {{ item.description }}
                            </div>
                        </div>
                        <div class="cellText">收款金额：{{ item.price }}元</div>
                        <div class="cellText">收款日期：{{ index }}</div>
                    </div>
                </van-list>
            </van-pull-refresh>
        </div>
        <div class="addBtn">
            <img src="../assets/icon/xz_icon.png" alt="" @click="add">
            <div class="exportBtn" @click="exportFile" v-show="powerList.includes('2') || powerList.includes('1')">导出</div>
        </div>
    </div>
</template>
  
<script>
import headTitle from "../components/headTitle.vue";
import Vue from 'vue';
import { Search, Toast, List, PullRefresh, Cell, SwipeCell, Button, Dialog } from 'vant';

import { projectStatusText, yearOption } from '../units/units'
import { gatheringList, gatheringExport } from '../api/gathering'
import { getToken } from '../units/auth'

Vue.use(Search);
Vue.use(Toast)
Vue.use(List)
Vue.use(PullRefresh)
Vue.use(Cell);
Vue.use(SwipeCell)
Vue.use(Button)
Vue.use(Dialog)
export default {
    name: 'pjCollection',
    components: {
        headTitle
    },
    data() {
        return {
            title: '项目收款列表',
            yearOption: yearOption,
            belongOption: [
                { text: "全部", value: null },
                { text: "大美", value: "1" },
                { text: "中创", value: "2" },
            ],
            value: '',
            list: [],
            loading: false,
            finished: false,
            refreshing: false,
            error: false,
            queryFrom: {
                currentYear: null,
                year: null,
                belong: null,
                vague: "",
                pageSize: 10,
                pageNum: 1,
            },
            routerPath: '/',
            powerList: [], //value 1总经理 2财务 3行政
            equipment: false,
            year: null
        }
    },
    beforeRouteEnter(to, from, next) {
        if (from.path == '/newPjCollection') {
            next(vm => {
                vm.onCancel()
            })
        } else if (from.path == '/') {
            next(vm => {
                vm.routerPath = '/'
            })
        }
        next()
    },
    // activated() {
    //     this.queryFrom.year = this.$route.query.year * 1 || null
    //     this.onRefresh()
    // },
    mounted() {
        if (this.$route.query.routerPath) {
            this.routerPath = this.$route.query.routerPath
        }
        if (this.$route.query.year) {
            this.year = this.queryFrom.currentYear = this.$route.query.year * 1
        }
        this.queryFrom.belong = this.$route.query.belong > 0 ? this.$route.query.belong : null
        this.getPowerList()
        this.isMobile()
    },
    methods: {
        //获取权限渲染页面
        getPowerList() {
            this.powerList = JSON.parse(getToken("powerList") || "[]");
        },
        getGatheringList() {
            this.loading = true;
            gatheringList(this.queryFrom).then((res) => {
                this.list =
                    this.queryFrom.pageNum == 1
                        ? res.data.rows
                        : this.list.concat(res.data.rows);
                this.refreshing = false;
                this.loading = false;
                this.queryFrom.pageNum = this.queryFrom.pageNum + 1;
                if (
                    res.data.total <=
                    (this.queryFrom.pageNum - 1) * this.queryFrom.pageSize
                ) {
                    this.finished = true;
                }
            });
        },
        stateText(t) {
            return projectStatusText(t)
        },
        onSearch(val) {
            this.queryFrom.vague = val
            this.onRefresh()
        },
        onCancel() {
            this.queryFrom.vague = ''
            this.onRefresh()
        },
        onRefresh() {
            // 清空列表数据
            this.finished = false;
            this.queryFrom.pageNum = 1
            this.list = []
            // 重新加载数据
            // 将 loading 设置为 
            this.getGatheringList();
        },
        changeYear(val) {
            if (this.queryFrom.currentYear) {
                this.queryFrom.currentYear = val
            } else {
                this.queryFrom.year = val;
            }
            this.year = val
            this.onRefresh();
        },
        changeBelong(val) {
            this.queryFrom.belong = val;
            this.onRefresh();
        },
        add() {
            this.$router.push({
                path: '/newPjCollection'
            })
        },
        exportFile() {
            if (!this.equipment) {
                Toast('此功能目前只能电脑端使用！')
                return
            }
            gatheringExport(this.queryFrom).then((res) => {
                var blob = new Blob([res.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                if ("download" in document.createElement("a")) {
                    // 非IE下载
                    var elink = document.createElement("a");
                    elink.download = "付款列表";
                    elink.style.display = "none";
                    elink.href = URL.createObjectURL(blob);
                    document.body.appendChild(elink);
                    elink.click();
                    URL.revokeObjectURL(elink.href); // 释放URL 对象
                    document.body.removeChild(elink);
                } else {
                    // IE10+下载
                    navigator.msSaveBlob(blob, "付款列表");
                }
            });
        },
        goDetails(id) {
            this.$router.push({
                path: '/collectionDetails', query: { id: id }
            })
        },
        format(time) {
            if (time) {
                return time.replace(' 00:00:00', '')
            }
        },
        isMobile() {
            this.equipment = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) ? false : true
        }
    }

}
</script>
  
<style lang="less" scoped>
.blue {
    color: #1989fa
}

.red {
    color: #E86F61;
}

.green {
    color: #00AB45;
}

.bkColor {
    width: 100vw;
    min-height: 100vh;
    background: #F6F6F6;
}

.search {
    width: 100vw;
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2vh;
    background: #fff;
    font-size: 15px;
    line-height: 8vh;
}

.searchTitle {
    margin-left: 6vw;
}

.list {
    margin-top: 2vh;
}


.listCell {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 5vw 1vh;
    background: #fff;
    text-align: left;
}

.cellName {
    width: 90%;
    font-size: 1rem;
    font-weight: 600;
    border-top: 2px solid #F6F6F6;
    padding-top: 1vh;
    padding-bottom: 1vh;

    .status {
        float: right;
        font-size: 0.8rem;
        font-weight: 500;
    }
}

/deep/ .van-list :first-of-type.listCell .cellName {
    border-top: unset;
}

.cellText {
    width: 70%;
    color: #797979;
    margin-bottom: 0.5vh;
    font-size: 0.7rem;
    word-break: break-all;
    line-height: 1;
}

.addBtn {
    width: 55px;
    height: 55px;
    position: fixed;
    top: 77%;
    left: 77%;

    img {
        width: 55px;
        height: 55px;
    }
}

.exportBtn {
    width: 50px;
    height: 50px;
    position: fixed;
    top: 66%;
    left: 77.1%;
    background: #3495fc;
    border-radius: 50%;
    line-height: 50px;
    color: #fff;
    box-shadow: 0px 6px 8px 0px rgba(122, 117, 117, 0.39);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
</style>